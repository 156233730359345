import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './ContactUs.css';
import config from '../../constants/request_config.json';

export const ContactUs = () => {
  const form = useRef();
  const navigate = useNavigate();

  const sendEmail = async (e) => {
    e.preventDefault();
    const baseURL = config.server_root;

    const formData = {
      user_name: form.current.user_name.value,
      user_email: form.current.user_email.value,
      message: form.current.message.value,
    };

    try {
      const response = await fetch(`${baseURL}leave-a-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      alert('Message left successfully.');
      console.log('Success:', data);
      navigate('/');
    } catch (error) {
      console.error('Registration error:', error);
      alert('Error leaving message. Please contact website administrator.');
    }
  };

  return (
    <div className="contact-us-container">
      <h1 className="contact-us-title">Contact Us</h1>
      <form ref={form} onSubmit={sendEmail}>
        <label>Name</label>
        <input type="text" name="user_name" />
        <label>Email</label>
        <input type="email" name="user_email" />
        <label>Message</label>
        <textarea name="message" />
        <input type="submit" value="Send" />
      </form>
    </div>
  );
};

export default ContactUs;
