import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import AboutPage from './components/about/AboutPage';
import ContactForm from './components/contact/ContactForm';
import LoginPage from './components/login/LoginPage';
import HomePage from './components/home/HomePage';
import Register from './components/register/Register';
import EmojiLogin from './components/login/EmojiLogin';
import Calendar from './components/calendar/Calendar';
import AdminP from "./components/admin/AdminPage";
import ParentPage from "./components/parent page/ParentPage";
import EmployeePage from './components/employee page/EmployeePage';
import ClientPage from './components/client page/ClientPage';
import GamesPage from './components/games page/GamesPage';
import ChatPage from './components/chat/ChatPage';
import Connect4 from './components/connect 4/connect4';
import Form from "./components/forms/formbuilder";
import Verify from './components/verify/Verify';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './components/contact/ContactUs.css';
import './components/footer/Footer.css';

function App() {
  const [isTextToSpeechEnabled, setIsTextToSpeechEnabled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isOnChat, setIsOnChat] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "CHEER";
    const item = localStorage.getItem('user');
    if (item && (location.pathname === '/ParentPage' || location.pathname === '/admin' || location.pathname === '/EmployeePage')
      || location.pathname == '/chat' || location.pathname == '/ClientPage' || location.pathname == '/games' || location.pathname == '/connect4') {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }

    if (location.pathname == '/chat' || location.pathname == '/admin' || location.pathname == '/ParentPage' || location.pathname == '/EmployeePage') {
      setIsOnChat(true);
    } else {
      setIsOnChat(false);
    }

  }, [location.pathname]);

  const speakText = (text) => {
    if (isTextToSpeechEnabled) {
      const utterance = new SpeechSynthesisUtterance(text);
      speechSynthesis.speak(utterance);
    }
  };

  const logout = () => {
    localStorage.removeItem('user');
    setIsLoggedIn(false);
    navigate('/'); // Navigate to home URL
  };

  const toggleTextToSpeech = () => {
    setIsTextToSpeechEnabled(!isTextToSpeechEnabled);
  };

  return (
    <div className="App">
      <header className="home-header">
        <Link to="/" className="logo">
          <img src="./1.png" alt="Company Logo" className="company-logo" />
          <h1 className="header-title">Ongoing Living & Learning Inc.</h1>
        </Link>
        <div className="menu-items">
          <button onClick={toggleTextToSpeech} className="text-to-speech-toggle">
            <img src={isTextToSpeechEnabled ? "./mic.png" : "./micoff.png"} alt="TTS Icon" style={{ marginRight: '8px' }} />
            {isTextToSpeechEnabled ? "Disable TTS" : "Enable TTS"}
          </button>
          <Link to="/"><button onMouseEnter={() => speakText("Home")} className="home-button-header"><img src="./h.png" alt="Home" className="sign-icon" /> Home </button></Link>
          {isLoggedIn ? (
            <button onClick={logout} className="login-button-header">
              <img src="./logoutpic.png" alt="Logout" className="logout-icon2" /> Logout
            </button>
          ) : (
            <Link to="/login-page">
              <button onMouseEnter={() => speakText("Caregiver Login")} className="login-button-header">
                <img src="./loginpic.png" alt="Login" className="login-icon2" /> Caregiver Login
              </button>
            </Link>
          )}
          {!isLoggedIn ?
            <Link to="/register"><button onMouseEnter={() => speakText("Sign Up")} className="signup-button-header"><img src="./sign.png" alt="Sign Up" className="sign-icon" />Sign Up</button></Link> : ''
          }
          <a onMouseEnter={() => speakText("Donate")} href='https://www.canadahelps.org/en/pages/olli-cheer/' target='_blank'>
            <button className='donate-button-header'><img src="./donate.png" alt="Donate" className="donate-icon" /> Donate</button>
          </a>
        </div>
      </header>
      <div className="main-content">
        <Routes>
          <Route path="/about" element={<AboutPage />} />
          <Route path="/form" element={<Form />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/login-page" element={<LoginPage />} />
          <Route path="/emoji-login-page" element={<EmojiLogin />} />
          <Route path="/register" element={<Register />} />
          <Route path="/Calendar" element={<Calendar />} />
          <Route path="/admin" element={<AdminP />} />
          <Route path="/ClientPage" element={<ClientPage isTextToSpeechEnabled={isTextToSpeechEnabled} />} />
          <Route path="/ParentPage" element={<ParentPage />} />
          <Route path="/EmployeePage" element={<EmployeePage />} />
          <Route path="/games" element={<GamesPage isTextToSpeechEnabled={isTextToSpeechEnabled} />} />
          <Route path="/chat" element={<ChatPage />} />
          <Route path="/connect4" element={<Connect4 />} />
          <Route path="/verify-email" element={<Verify />} />
          <Route path="/" element={<HomePage isTextToSpeechEnabled={isTextToSpeechEnabled} />} />
        </Routes>
      </div>
      { !isOnChat ?
      <footer className="footer">
        <div className="footer-section">
          <h2>Ongoing Living & Learning Inc</h2>
          <span className="credit">Developed by: Omar El Terras, Bilal Saad, Mohamed El Dogdog, Manav Preet Singh</span>
        </div>
        <div className="footer-section facebook-section">
          <h3>Find us on Facebook</h3>
          <a href="https://www.facebook.com/cheer.2023">CHEER Facebook Page</a>
          <a href="https://www.facebook.com/familyconnectionscheer">CHEER Connections Facebook Page</a>
          <a href="https://www.facebook.com/people/Roxys-Mini-Golf-and-Cheer-Canteen/100057044577232/">CHEER Works Facebook Page</a>
        </div>
        <div className="footer-section contact-section">
          <h3>Contact Us</h3>
          <Link to="/contact" className="footer-button">
            <img src="./contact.png" alt="Contact" className="contact-icon" />Contact
          </Link>
        </div>
      </footer> : <></>
      }
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
