import React, { useState, useEffect } from 'react';
import config from '../../constants/request_config.json';
import './menu_display.css'; // You can reuse the same CSS file or create a new one if needed.

function MenuDisplay() {
    const baseURL = config.server_root;
    const [menu, setMenu] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch the current menu
    useEffect(() => {
        fetch(`${baseURL}/menu`)
            .then(response => response.json())
            .then(data => {
                setMenu(data);
                setLoading(false);
            })
            .catch(err => {
                setError(err);
                setLoading(false);
            });
    }, [baseURL]);

    if (loading) return <div>Loading...</div>;
    if (error) return (
        <div className="menu-display-main">
            <hr></hr>
            <h2>Menu Items</h2>
            <p>Come by our store and pick up some tasty treats!</p>
            <div className="menu-display">
                <div></div><div></div>
                <span>Menu Coming Soon!</span>
            </div>
            <hr></hr>
        </div>
    );

    return (
        <div className="menu-display-main">
            <hr></hr>
            <h2>Menu Items</h2>
            <p>Come by our store and pick up some tasty treats!</p>
            <div className="menu-display">
                {menu.length > 0 ? menu.map((item, index) => (
                    <div key={index} className="item">
                        <span className="menu-item-name"><strong>{item.name}</strong></span>
                        <span className="menu-item-price">${item.price}</span>
                        {item.image && <img src={item.image} alt={item.name} width="100" className="menu-item-img" />}
                    </div>
                )) : (<span>Menu Coming Soon!</span>)}
            </div>
            <hr></hr>
        </div>
    );
}

export default MenuDisplay;