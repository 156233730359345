import React, { useState, useEffect } from 'react';
import "./menu.css";
import config from '../../constants/request_config.json';

function Menu() {
    const baseURL = config.server_root;
    const [menu, setMenu] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch the current menu
    useEffect(() => {
        fetch(`${baseURL}/menu`)
            .then(response => response.json())
            .then(data => {
                setMenu(data);
                setLoading(false);
            })
            .catch(err => {
                setError(err);
                setLoading(false);
            });
    }, [baseURL]);

    // Handle menu item change
    const handleChange = (index, field, value) => {
        const updatedMenu = [...menu];
        updatedMenu[index][field] = value;
        setMenu(updatedMenu);
    };

    // Handle file change
    const handleFileChange = (index, file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const updatedMenu = [...menu];
            updatedMenu[index].image = reader.result;
            setMenu(updatedMenu);
        };
        reader.readAsDataURL(file);
    };

    // Handle adding a new item
    const handleAddItem = () => {
        setMenu([...menu, { name: '', price: '', image: '' }]);
    };

    // Handle removing an item
    const handleRemoveItem = (index) => {
        const updatedMenu = menu.filter((item, i) => i !== index);
        setMenu(updatedMenu);
    };

    // Handle form submit
    const handleSubmit = (e) => {
        e.preventDefault();
        fetch(`${baseURL}/update-menu`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(menu)
        })
            .then(response => response.json())
            .then(data => {
                alert("Menu updated successfully!");
            })
            .catch(err => {
                setError(err);
                alert("Failed to update menu.");
            });
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div className="menu-builder">
            <form onSubmit={handleSubmit}>
                <div className='menu-list'>
                    {menu.map((item, index) => (
                        <div key={index} className="menu-item">
                            <div class='menu-item-details'>
                                <div className='item-name-price'>
                                    <div>
                                        <label>Item Name</label>
                                        <input
                                            type="text"
                                            value={item.name}
                                            onChange={(e) => handleChange(index, 'name', e.target.value)}
                                            placeholder="Item Name"
                                        />
                                    </div>
                                    <div>
                                        <label>Price ($ CAD)</label>
                                        <input
                                            type="text"
                                            value={item.price}
                                            onChange={(e) => handleChange(index, 'price', e.target.value)}
                                            placeholder="Item Price"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => handleFileChange(index, e.target.files[0])}
                                    />
                                    {item.image && <img src={item.image} alt="Menu item" width="100" />}
                                </div>
                            </div>
                            <button type="button" onClick={() => handleRemoveItem(index)}>Remove</button>
                        </div>
                    ))}
                </div>
                <button type="button" onClick={handleAddItem}>Add Item</button>
                <button type="submit">Submit</button>
            </form>
        </div>
    );
}

export default Menu;
