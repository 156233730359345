// DemoApp.js
import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Modal from '../modals/modal'; // Import the Modal component for adding events
import EditEventModal from '../events/EditEventModal'; // Import the EditEventModal component for editing/deleting events
import './Calendar.css';
import config from '../../constants/request_config.json';

function DemoApp() {
  const [events, setEvents] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  
  const baseURL = config.server_root;

  const fetchEvents = async () => {
    const response = await fetch(`${baseURL}events`);
    const fetchedEvents = await response.json();
    setEvents(fetchedEvents);
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleDateClick = (arg) => {
    console.log(arg.dateStr)
    setModalOpen({ date: arg.dateStr });
  };

  const closeModal = () => {
    setModalOpen(false);
    setEditModalOpen(false);
    setSelectedEvent(null);
  };

  const saveEvent = async (title, start, end) => {
    const newEvent = { title, start, end };
    let newEventData = await addEventToDatabase(newEvent);
    
    if (newEventData && newEventData.event) {
      setEvents(currentEvents => [...currentEvents, newEventData.event]);
    } else {
      // Handle error: event was not created properly or no data returned
      console.error("Failed to add new event:", newEventData);
    }
    
    closeModal();
  };

  const addEventToDatabase = async (event) => {
    const response = await fetch(`${baseURL}events`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(event),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log("Event added:", data);
    return data; // Assuming the backend returns the newly added event with an ID
  };

  const handleEventClick = (clickInfo) => {
    setSelectedEvent({
      id: clickInfo.event._def.extendedProps._id,
      title: clickInfo.event.title,
      start: clickInfo.event.startStr,
      end: clickInfo.event.endStr,
    });
    setEditModalOpen(true);
  };

  const updateEventInDatabase = async (id, updatedEvent) => {
    console.log(id);
    try {
      const response = await fetch(`${baseURL}events/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedEvent),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
   } catch (e) {
      alert("Something went wrong, please refresh the page and try again.")
   }

  };

  const updateEvent = async (id, title, start, end) => {
    const updatedEvent = { title, start, end };
    await updateEventInDatabase(id, updatedEvent);
    fetchEvents()
    closeModal();
  };

  const deleteEventInDatabase = async (event) => {
    const { title, start, end } = event;
    await fetch(`${baseURL}events`, {
        method: 'DELETE', // Using POST as a workaround
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ title, start, end }),
    });
};

  const deleteEvent = async () => {
    await deleteEventInDatabase(selectedEvent);
    fetchEvents()
    closeModal();
  };

  return (
    <div className='my-calendar'>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          start: 'today prev,next',
          center: 'title',
          end: 'dayGridMonth timeGridWeek timeGridDay',
        }}
        height="700px"
        events={events}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
      />
      {isModalOpen && (
        <Modal
          isOpen={Boolean(isModalOpen)}
          onClose={closeModal}
          onSave={saveEvent}
          date={isModalOpen.date}
        />
      )}
      {isEditModalOpen && selectedEvent && (
        <EditEventModal
          isOpen={Boolean(isEditModalOpen)}
          onClose={closeModal}
          onUpdate={updateEvent}
          onDelete={deleteEvent}
          event={selectedEvent}
        />
      )}
    </div>
  );
}

export default DemoApp;
