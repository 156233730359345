// DemoApp.js
import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Modal from '../modals/modals'; // Import the Modal component for adding events
import EditEventModal from '../events/editeventmodals'; // Import the EditEventModal component for editing/deleting events
import '../calendar/Calendar.css';
import config from '../../constants/request_config.json';

function DemoApp() {
  const [events, setEvents] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);  

  const baseURL = config.server_root;

  const fetchEvents = async () => {
    const response = await fetch(`${baseURL}schedulehours`);
    const fetchedEvents = await response.json();
    setEvents(fetchedEvents);
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleDateClick = (arg) => {
    console.log(arg.dateStr)
    setModalOpen({ date: arg.dateStr });
  };

  const closeModal = () => {
    setModalOpen(false);
    setEditModalOpen(false);
    setSelectedEvent(null);
  };

  const saveEvent = async (title, userID,start, end) => {
    console.log(userID)
    const newEvent = { title, userID,start, end };
    await addEventToDatabase(newEvent);
    setEvents(currentEvents => [...currentEvents, newEvent]);
    closeModal();
  };

  const addEventToDatabase = async (event) => {
    const response = await fetch(`${baseURL}schedulehours`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(event),
    });
    const data = await response.json();
    return data; // Assuming the backend returns the newly added event with an ID
  };

  const handleEventClick = (clickInfo) => {

    setSelectedEvent({
      id: clickInfo.event._def.extendedProps._id,
      title: clickInfo.event.title,
      userID: clickInfo.event._def.extendedProps.userID,
      start: clickInfo.event.startStr,
      end: clickInfo.event.endStr,
    });
    setEditModalOpen(true);
  };

  const updateEventInDatabase = async (id, updatedEvent) => {
    const response = await fetch(`${baseURL}schedulehours/${id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updatedEvent),
    });
  };

  const updateEvent = async (id, title, userID ,start, end) => {
    const updatedEvent = { title, userID, start, end };
    await updateEventInDatabase(id, updatedEvent);
    fetchEvents()
    closeModal();
  };

  const deleteEventInDatabase = async (event) => {
    const { title, start, end } = event;
    await fetch(`${baseURL}schedulehours`, {
        method: 'DELETE', // Using POST as a workaround
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ title, start, end }),
    });
};

  const deleteEvent = async () => {
    await deleteEventInDatabase(selectedEvent);
    fetchEvents()
    closeModal();
  };

  return (
    <div className='my-calendar'>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          start: 'today prev,next',
          center: 'title',
          end: 'dayGridMonth timeGridWeek timeGridDay',
        }}
        height="700px"
        events={events}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
      />
      {isModalOpen && (
        <Modal
          isOpen={Boolean(isModalOpen)}
          onClose={closeModal}
          onSave={saveEvent}
          date={isModalOpen.date}
        />
      )}
      {isEditModalOpen && selectedEvent && (
        <EditEventModal
          isOpen={Boolean(isEditModalOpen)}
          onClose={closeModal}
          onUpdate={updateEvent}
          onDelete={deleteEvent}
          event={selectedEvent}
        />
      )}
    </div>
  );
}

export default DemoApp;
