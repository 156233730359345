import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Verify.css';
import config from '../../constants/request_config.json';

const Verify = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const baseURL = config.server_root;

    useEffect(() => {
        const verifyEmail = async () => {
            const params = new URLSearchParams(location.search);
            const token = params.get('token');

            if (token) {
                try {
                    const response = await fetch(`${baseURL}verify-email?token=${token}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const text = await response.text();

                    if (text.includes('Email verified successfully')) {
                        alert('Thank you for verifying your account.');
                        console.log('Success:', text);
                        navigate('/login-page');
                    } else {
                        throw new Error(text);
                    }
                } catch (error) {
                    console.error('Verification error:', error);
                    alert('Verification failed. Please contact the website administrator.');
                }
            }
        };

        verifyEmail();
    }, [location.search, navigate, baseURL]);

    return (
        <div className="verify-container">
            <h2>Verifying your account...</h2>
        </div>
    );
};

export default Verify;
